import React from "react"
import Footer from "../components/footer"
import "../styles/zenlayer-inside.less"
import SEO from "../components/SEO"
import { SolutionTopBanner } from "../components/block"
const loadText = require("src/utils").loadText
const data = loadText("zenlayer-insider")
import { Submit } from "./contact-us"
const B1 = () => (
  <div className="zenlayer-inside-b1">
    <div className="zenlayer-inside-b1-content">
      <div className="imgContainer">
        <img src="/zenlayer-inside/b1-pic.png" alt={data.b1.title} />
      </div>
      <div className="content">
        <div className="font-28 semi-bold t-color">
          <div style={{ maxWidth: "422px" }}>{data.b1.title}</div>
        </div>
        {data.sns.map(({ name, link }) => (
          <img
            src={`/zenlayer-inside/${name}.svg`}
            alt={name}
            onClick={() => {
              window.open(link)
            }}
          />
        ))}
      </div>
    </div>
  </div>
)
const Sub = () => (
  <div className="zenlayer-inside-sub">
    <div className="zenlayer-inside-subContent full-bg cover-1920">
      <div className="font-36 semi-bold">{data.subscribe.title}</div>
      <div
        className="font-20 mt-24 block-middle description"
        style={{ maxWidth: "960px" }}
      >
        {data.subscribe.description}
      </div>
      <div className="mt-48">
        <Submit source="zenlayer-insider" />
      </div>
    </div>
  </div>
)
export default function Home() {
  return (
    <div className="zenlayer-inside">
      <SEO {...data.seo} />
      <SolutionTopBanner
        {...data.topBanner}
        bgColor="linear-gradient(182deg,rgba(199,236,255,1) 0%,rgba(238,249,255,1) 100%)"
        bgPic={"/zenlayer-inside/topBanner-bg.svg"}
        height={480}
        bannerPic={{
          src: `/zenlayer-inside/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        pic={{
          src: "/zenlayer-inside/topBanner-pic.png",
          style: {
            right: "100px",
            bottom: "-40px",
            position: "absolute",
            width: "610px",
          },
        }}
      />
      <B1 />
      <Sub />
      <Footer />
    </div>
  )
}
