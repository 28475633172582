import React, { useState, useEffect } from 'react'
import SEO from '../components/SEO'
import { graphql, Link } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import '../styles/contact.less'
import { useEmailForm } from '../components/form'
import { SupportPopButton, ContactPopButton } from '../components/form-biz'
import { Button, Input } from '../components/ui'
import { lang, loadScriptToHead } from '../utils'
import { Detail } from './support'
const loadText = require('src/utils').loadText
const url = loadText('url')
const static_data = loadText('contact-us')
const TopBlock = ({
  icon,
  title,
  telephones,
  // submitText,
  children,
  subtext,
}: // style = {},
{
  icon: string
  title: string
  telephones: any
  subtext?: string
  children: JSX.Element
  // style?: React.CSSProperties
}) => {
  return (
    <div className="contact-topBlock">
      <img src={icon} alt={title} className="contact-topBlock-icon" />
      <div className="font-36 t-color semi-bold">{title}</div>
      <div className="mt-24">
        {telephones.map(({ name, telephone }) => (
          <div className="contact-topBlock-telephone font-20 semi-bold">
            <div className="t-color">{name}</div>
            <span>{telephone}</span>
          </div>
        ))}
      </div>
      {subtext && (
        <div className="contact-topBlock-subtext mt-16 ">{subtext}</div>
      )}
      <div className="contact-topBlock-submit">
        {/* <Button width={208}>{submitText}</Button> */}
        {children}
      </div>
    </div>
  )
}
const TopBanner = () => (
  <div className="contact-topContainer">
    <div className="contact-topWrapper">
      <div className="headerContainer">
        <Header logo="/logo-blue.svg" />
      </div>
      <div className="contact-intro page-middle">
        <div className="contact-intro-content">
          <div className="font-56 semi-bold t-color">
            {static_data.topBanner.title}
          </div>
          <div className="mt-24 font-20 description">
            {static_data.topBanner.description}
          </div>
        </div>
      </div>
    </div>
  </div>
)
const sortByName = (a, b) => a.name.localeCompare(b.name)
const Office = ({ data }) => {
  const heads = data.filter(({ headquarters }) => headquarters)
  const normal = data.filter(({ headquarters }) => !headquarters)
  // heads.sort(sortByName)
  // normal.sort(sortByName)
  const _data = heads.concat(normal)
  console.log(_data, heads, normal)
  return (
    <div className="contact-address page-middle">
      <div className="font-36 t-color semi-bold text-center">
        {/* Office addresses */}
        {static_data.officeAddress.title}
      </div>
      <div className="topPic">
        <img src="/contact/office-pic-m.png" alt="Office" />
      </div>
      <div className="contact-address-itemWrap">
        <div className="contact-address-itemContainer">
          {_data.map(({ name, address, headquarters, longitude, latitude }) => (
            <div className="contact-address-item" key={name}>
              <div>
                <div className="font-20 semi-bold t-color dib">{name}</div>
                {headquarters && (
                  <div className="contact-address-flag">
                    <img src="/contact/flag.svg" alt="flag" />
                    <span>{static_data.officeAddress.headquarters}</span>
                  </div>
                )}
              </div>
              <div className="mt-8 font-16">
                {!address ? (
                  <div className="address">
                    {static_data.officeAddress.commingSoon}
                  </div>
                ) : (
                  <div className="address" data-json={address}>
                    <div dangerouslySetInnerHTML={{ __html: address }} />
                    <a
                      className="viewMap mt-8"
                      onClick={() => {
                        window.open(
                          lang === 'en'
                            ? `http://maps.google.com/maps?q=${longitude},${latitude}`
                            : `http://api.map.baidu.com/marker?location=${longitude},${latitude}&output=html`
                        )
                        // setHighlight([longitude, latitude])
                      }}
                    >
                      {static_data.officeAddress.viewMap}
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{ width: '444px', display: 'inline-block' }}
          className="bottomPic"
        >
          <img src="/contact/office-pic.png" alt="Office" />
        </div>
      </div>
    </div>
  )
}
export const Submit = ({ source }) => {
  const {
    val,
    error,
    onChange,
    canSubmit,
    loading,
    onSubmit,
    ButtonWrap,
  } = useEmailForm({ source })
  return (
    <div className="contact-subBanner-sub">
      <div style={{ display: 'inline-block' }}>
        <Input
          name={static_data.email}
          width={326}
          value={val}
          onChange={onChange}
          error={error}
        />
      </div>
      <div style={{ display: 'inline-block' }} className="vtop">
        <ButtonWrap>
          <Button
            width={140}
            // disabled={!canSubmit}
            onClick={() => {
              if (canSubmit) {
                onSubmit()
              }
            }}
            loading={loading}
            size="l"
          >
            {static_data.subscribeBanner.buttonText}
          </Button>
        </ButtonWrap>
      </div>
    </div>
  )
}
const Subscribe = () => {
  return (
    // <div style={{ backgroundColor: "#f0f9fe" }}>
    <div className="contact-subBanner-container">
      <div className="contact-subBanner page-middle">
        <div className="page-middle">
          <div className="contact-subBanner-text">
            <div className="contact-subBanner-title font-28 semi-bold">
              {static_data.subscribeBanner.title}
            </div>
            <div className="contact-subBanner-desc font-16 mt-8">
              {static_data.subscribeBanner.description}
            </div>
          </div>
          <div style={{ display: 'inline-block' }} className="btn-wrapper">
            <Submit source={'contact-us'} />
          </div>
          {/* <div className="contact-subBanner-sub">
          <div style={{ display: "inline-block" }}>
            <Input name="email address" width={326} />
          </div>
          <div style={{ display: "inline-block" }} className="vtop">
            <Button width={140}>
              {static_data.subscribeBanner.buttonText}
            </Button>q
          </div>
        </div> */}
          <div style={{ verticalAlign: 'top' }}>
            {['facebook', 'linkedin', 'twitter'].map((key) => (
              <img
                src={`/contact/${key}.svg`}
                alt={key}
                key={key}
                onClick={() => {
                  window.open(url[key].link)
                }}
                className="contact-subBanner-icon"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    // </div>
  )
}
const Email = () => {
  return (
    <div className="contact-email">
      <div className="contact-email-content">
        <div className="contact-email-contentRow">
          <div className="imgContainer">
            <img src="/contact/media.png" alt="media_inquiry" />
          </div>
          <div className="text-center">
            <div className="font-28 semi-bold t-color">
              {static_data.inquiry.media.title}
            </div>
            <a className="mt-16 font-20" href="mailto:marketing@zenlayer.com">
              {static_data.inquiry.media.email}
            </a>
          </div>
        </div>
        <div className="contact-email-contentRow">
          <div className="imgContainer">
            <img src="/contact/peer.png" alt="peer_inquiry" />
          </div>
          <div className="text-center">
            <div className="font-28 semi-bold t-color">
              {static_data.inquiry.peer.title}
            </div>
            <a className="mt-16 font-20" href="mailto:peering@zenlayer.com">
              {static_data.inquiry.peer.email}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default function ({ data }) {
  const { allStrapiCompanyAddresses } = data
  return (
    <div className="contact">
      <SEO {...static_data.seo} />
      <div style={{ backgroundColor: '#f0f9fe' }}>
        <TopBanner />
      </div>
      <div
        className={`contact-topBlock-container page-middle ${
          !static_data.contact[1] ? 'single' : ''
        }`}
      >
        {/* <div className="contact-topBlock-wrapper"> */}
        <TopBlock icon={'/contact/sales_i.svg'} {...static_data.contact[0]}>
          <ContactPopButton buttonWidth={208} source="contactUs">
            {static_data.contact[0].submitText}
          </ContactPopButton>
        </TopBlock>
        {static_data.contact[1] && (
          <TopBlock icon={'/contact/support_i.svg'} {...static_data.contact[1]}>
            <SupportPopButton buttonWidth={208} source="contactUs">
              {static_data.contact[1].submitText}
            </SupportPopButton>
          </TopBlock>
        )}

        {/* </div> */}
      </div>
      <div style={{ marginTop: '48px' }}>
        <Detail />
      </div>
      <Email />
      <Office data={allStrapiCompanyAddresses.nodes} />
      <Subscribe />
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiCompanyAddresses {
      nodes {
        longitude
        latitude
        headquarters
        name
        address
      }
    }
  }
`
